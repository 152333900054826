import { useLocation } from "react-router-dom";
import {
  BloodQueryType,
  bloodQueryItems,
  defaulBloodQuery,
} from "../types/bloodQuery.type";

export const useBlood = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  let queryData: BloodQueryType = defaulBloodQuery;

  bloodQueryItems.forEach((item) => {
    const value = params.get(item);
    queryData = Object.assign(queryData, { [item]: value });
  });

  return queryData;
};
