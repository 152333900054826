import React from "react";
import { Absolute } from "./ModalStyles";

const Modal = ({
  children,
  closeModal,
}: {
  children: React.ReactElement;
  closeModal: (e: React.TouchEvent | React.MouseEvent) => void;
}) => {
  return <Absolute onClick={closeModal}>{children}</Absolute>;
};

export default Modal;
