import React from "react";
import { PopUpSubTitle, PopUpTitle, Row } from "../PopUpStyles";
import * as Svg from "../../../icon/index";

const Header = ({
  title,
  sub,
  closeClick,
}: {
  title: string;
  sub: string;
  closeClick: (e: React.TouchEvent | React.MouseEvent) => void;
}) => {
  return (
    <Row>
      <div>
        <PopUpTitle>{title}</PopUpTitle>
        <PopUpSubTitle>{sub}</PopUpSubTitle>
      </div>
      <Svg.Close onClick={closeClick} />
    </Row>
  );
};

export default Header;
