import styled from "styled-components";
import { pxToVh } from "../../../utils/pxToVh";
import { theme } from "../../../styles/theme";

export const WinLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: ${pxToVh(310)};
  height: ${pxToVh(422)};
  background-color: ${theme.palette.white};
  border-radius: 20px;
  padding: ${pxToVh(44)} ${pxToVh(25)};

  svg {
    margin-top: ${pxToVh(40)};
    width: ${pxToVh(154)};
    height: auto;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${pxToVh(16)};
  line-height: 150%;
`;

export const Title = styled.h2`
  font-size: ${theme.fontSize[28]};
  font-weight: ${theme.fontWeight.bold};
  margin: 0;
`;

export const SubTitle = styled.span`
  font-size: ${theme.fontSize[16]};
  font-weight: ${theme.fontWeight.regular};
  color: ${theme.palette.message[300]};
`;

export const ModalCloseButton = styled.button`
  width: ${pxToVh(250)};
  height: ${pxToVh(50)};
  background-color: ${theme.palette.black};
  color: ${theme.palette.white};
  border-radius: 6px;
  font-size: ${theme.fontSize[16]};
  cursor: pointer;
`;
