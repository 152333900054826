import { ReactElement } from "react";
import * as Svg from "../components/icon/index";
import useLocalization from "../hooks/useLocalization";

export const bloodSvg: Record<
  string,
  { chart: ReactElement; type: ReactElement }
> = {
  a: { chart: <Svg.ChartA />, type: <Svg.TypeA /> },
  b: { chart: <Svg.ChartB />, type: <Svg.TypeB /> },
  c: { chart: <Svg.ChartC />, type: <Svg.TypeC /> },
  d: { chart: <Svg.ChartD />, type: <Svg.TypeC /> },
  e: { chart: <Svg.ChartE />, type: <Svg.TypeE /> },
  f: { chart: <Svg.ChartF />, type: <Svg.TypeF /> },
  g: { chart: <Svg.ChartG />, type: <Svg.TypeG /> },
};

export const BloodResult = (): Record<
  string,
  { title: string; comment: string; type: string }
> => {
  const lang = useLocalization();
  return {
    a: {
      title: lang.BloodVesselResultHealthTitle,
      comment: lang.BloodVesselResultSummaryHealth,
      type: lang.BloodVessleStandard,
    },
    b: {
      title: lang.BloodVesselResultHealthTitle,
      comment: lang.BloodVesselResultSummaryHealth,
      type: lang.BloodVessleStandard,
    },
    c: {
      title: lang.BloodVesselResultWaringTitle,
      comment: lang.BloodVesselResultSummaryWaring,
      type: lang.BloodVessleWarning,
    },
    d: {
      title: lang.BloodVesselResultWaringTitle,
      comment: lang.BloodVesselResultSummaryWaring,
      type: lang.BloodVessleWarning,
    },
    e: {
      title: lang.BloodVesselResultDangerTitle,
      comment: lang.BloodVesselResultSummaryDanger,
      type: lang.BloodVessleDanger,
    },
    f: {
      title: lang.BloodVesselResultDangerTitle,
      comment: lang.BloodVesselResultSummaryDanger,
      type: lang.BloodVessleDanger,
    },
    g: {
      title: lang.BloodVesselResultDangerTitle,
      comment: lang.BloodVesselResultSummaryDanger,
      type: lang.BloodVessleDanger,
    },
  };
};
// 202407111516 (date)

export const calcBloodStateByPulse = (
  avgMin: string | number,
  avgMax: string | number,
  pulse: string | number
): "high" | "row" | "middle" => {
  if (+pulse > +avgMax) {
    return "high";
  } else if (+avgMin > +pulse) {
    return "row";
  } else return "middle";
};
