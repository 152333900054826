import { useLocation } from "react-router-dom";
import {
  IQueryData,
  defaultQueryData,
  queryItem,
} from "../types/queryData.type";

export const useQueryString = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let queryData: IQueryData = defaultQueryData;

  queryItem.forEach((item: string) => {
    const value = params.get(item);
    queryData = Object.assign(queryData, {
      [item]: value,
    });
  });

  return { queryData };
};
