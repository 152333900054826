import styled from "styled-components";
import { pxToVh } from "../../../utils/pxToVh";
import { theme } from "../../../styles/theme";

export const PopupContainer = styled.div<{ $isGraph: boolean }>`
  &::-webkit-scrollbar {
    display: none;
  }
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: ${pxToVh(32)} 0;
  width: ${({ $isGraph }) => ($isGraph ? pxToVh(310) : pxToVh(340))};
  height: ${({ $isGraph }) => ($isGraph ? pxToVh(560) : pxToVh(578))};
  padding: ${pxToVh(44)} ${pxToVh(25)};
  border-radius: ${pxToVh(20)};
  background-color: ${theme.palette.white};
  overflow-y: scroll;
  /* animation:  0.4s ease-in-out; */
`;

export const PopUpTitle = styled.h3`
  font-size: ${theme.fontSize[20]};
  font-weight: ${theme.fontWeight.bold};
  line-height: 150%;
  white-space: pre-line;
  margin: 0;
`;

export const PopUpSubTitle = styled.span`
  font-size: ${theme.fontSize[16]};
  font-weight: ${theme.fontWeight.regular};
  line-height: 150%;
  color: ${theme.palette.message[300]};
`;

export const GraphBody = styled.div`
  svg {
    width: ${pxToVh(200)};
    height: 100%;
  }
`;

export const TableBody = styled.div`
  svg {
    width: ${pxToVh(260)};
    height: 100%;
  }
`;

export const SvgContainer = styled.div<{ $width: string; $height?: string }>`
  svg {
    width: ${({ $width }) => $width};
    height: ${({ $height }) => $height || "50%"};
    margin-top: ${pxToVh(8)};
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  svg {
    width: ${pxToVh(24)};
    height: ${pxToVh(24)};
  }
`;
