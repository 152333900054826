import React from "react";
import { PopupContainer, SvgContainer } from "./PopUpStyles";
import { ModalType } from "../../../types/modal.type";
import Header from "./header/Header";
import { popUpSvg, TitleStore } from "../../../utils/popUpStore";
import Modal from "../../modal/Modal";
import { useParams } from "react-router-dom";
import { LangType } from "../../../types/lang.type";

const PopUp = ({
  isShow,
  closeModal,
}: {
  isShow: ModalType;
  closeModal: (e: React.TouchEvent | React.MouseEvent) => void;
}) => {
  const { lang } = useParams();
  if (!isShow) return <></>;

  return (
    <Modal closeModal={closeModal}>
      <PopupContainer
        $isGraph={isShow === "graph"}
        onClick={(e: React.MouseEvent | React.TouchEvent) =>
          e.stopPropagation()
        }
      >
        <Header
          title={TitleStore()[isShow].title}
          sub={TitleStore()[isShow].sub}
          closeClick={closeModal}
        />
        <SvgContainer
          $width={popUpSvg[isShow].width}
          $height={popUpSvg[isShow].height}
        >
          {popUpSvg[isShow].chart[(lang || "ko") as LangType]}
          {popUpSvg[isShow].info[(lang || "ko") as LangType]}
        </SvgContainer>
      </PopupContainer>
    </Modal>
  );
};

export default PopUp;
