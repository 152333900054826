import styled from "styled-components";
import { pxToVh } from "../../utils/pxToVh";

export const SubTitleContainer = styled.span<{ $subWeight?: string }>`
  font-size: ${({ theme }) => theme.fontSize.ladium};
  font-weight: ${({ theme, $subWeight }) =>
    $subWeight || theme.fontWeight.regular};
  margin-top: ${pxToVh(6.5)};
  color: ${({ theme }) => theme.palette.message[300]};
  white-space: pre-line;
  line-height: 150%;
`;
