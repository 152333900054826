import styled from "styled-components";
import { pxToVh } from "../../../utils/pxToVh";
import { theme } from "../../../styles/theme";

export const BloodSquareContainer = styled.div`
  display: flex;
  width: 100%;
  gap: ${pxToVh(18)};
  justify-content: space-between;
  margin-bottom: ${pxToVh(28)};
`;

export const BloodSquareBox = styled.div`
  width: 100%;
  margin-top: ${pxToVh(28)};
  /* width: ${pxToVh(162)}; */
  /* height: ${pxToVh(130)}; */
  padding: ${pxToVh(25)} ${pxToVh(20)} ${pxToVh(18)} ${pxToVh(20)};
  border: 1px solid ${theme.palette.message[300]};
  border-radius: ${pxToVh(15)};
  display: flex;
  flex-direction: column;
`;

export const SvgType = styled.div<{ width: number; height: number }>`
  width: ${pxToVh(64)};
  height: ${pxToVh(64)};

  svg {
    width: ${({ width }) => pxToVh(width)};
    height: ${({ height }) => pxToVh(height)};
  }
`;

export const InfoPlaceholder = styled.span`
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.palette.message[400]};
  margin-top: ${pxToVh(8)};
  opacity: 0.7;
  line-height: 150%;
`;

export const Content = styled.span`
  font-size: ${theme.fontSize.ladium};
  font-weight: ${theme.fontWeight.bold};
  margin-top: ${pxToVh(4)};
  line-height: 150%;
`;

export const ResultBloodComment = styled.span`
  font-size: ${theme.fontSize.ladium};
  font-weight: ${theme.fontWeight.regular};
  line-height: 150%;
  margin-bottom: ${pxToVh(18)};
`;

export const QuestionLayout = styled.div`
  width: 100%;
`;

export const ResultDetail = styled.span`
  font-size: ${theme.fontSize[18]};
  font-weight: ${theme.fontWeight.bold};
  margin-top: ${pxToVh(44)};
  width: 100%;
`;
