import React from "react";
import {
  DataContainer,
  DataInProgress,
  ProgressTitle,
  ProgressValue,
  WidthBarLayout,
} from "./BloodProgressStyles";
import { pxToVh } from "../../../utils/pxToVh";

interface BloodProgressProps {
  title: string;
  target: number;
  min?: number;
  isStandard?: boolean;
}

const BloodProgress = ({
  title,
  target,
  min,
  isStandard,
}: BloodProgressProps) => {
  return (
    <DataContainer>
      <DataInProgress>
        <ProgressTitle>{title}</ProgressTitle>
        <WidthBarLayout $width={pxToVh(170)}>
          <ProgressValue $target={target} $isStandard={isStandard} $min={min} />
        </WidthBarLayout>
      </DataInProgress>
    </DataContainer>
  );
};

export default BloodProgress;
