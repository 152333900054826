import React from "react";
import { CardListContainer } from "../card/CardStyles";
import Card from "../card/Card";
import { TFilteredData } from "../../types/queryData.type";

const CardList = ({ queryData }: { queryData: TFilteredData[] }) => {
  return (
    <CardListContainer>
      {queryData.map((cardData, i) => (
        <Card key={i} cardData={cardData} />
      ))}
    </CardListContainer>
  );
};

export default CardList;
