import React from "react";
import Modal from "../Modal";
import {
  ModalCloseButton,
  SubTitle,
  TextContainer,
  Title,
  WinLayout,
} from "./WinStyles";
import * as Svg from "../../icon/index";

const Win = ({
  closeModal,
}: {
  closeModal: (e: React.TouchEvent | React.MouseEvent) => void;
}) => {
  return (
    <Modal closeModal={closeModal}>
      <WinLayout
        onClick={(e: React.TouchEvent | React.MouseEvent) =>
          e.stopPropagation()
        }
      >
        <Svg.Coffe />
        <TextContainer>
          <Title>축하합니다!</Title>
          <SubTitle>커피 기프티콘에 당첨되셨어요.</SubTitle>
        </TextContainer>
        <ModalCloseButton onClick={closeModal}>확인</ModalCloseButton>
      </WinLayout>
    </Modal>
  );
};

export default Win;
