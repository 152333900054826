import React from "react";
import { SubTitleContainer } from "./SubTitleStyles";

const SubTitle = ({
  subTitle,
  subWeight,
}: {
  subTitle: string;
  subWeight?: string;
}) => {
  return (
    <SubTitleContainer $subWeight={subWeight}>{subTitle}</SubTitleContainer>
  );
};

export default SubTitle;
