import React from "react";
import {
  BloodSquareBox,
  Content,
  InfoPlaceholder,
  SvgType,
} from "./BloodSquareContainer";

const BloodSquare = ({
  svg,
  infoPlaceholder,
  content,
}: {
  svg: React.ReactElement;
  infoPlaceholder: string;
  content: string | number;
}) => {
  return (
    <BloodSquareBox>
      <SvgType width={49} height={49}>
        {svg}
      </SvgType>
      <InfoPlaceholder>{infoPlaceholder}</InfoPlaceholder>
      <Content>{content}</Content>
    </BloodSquareBox>
  );
};

export default BloodSquare;
