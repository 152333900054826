import styled from "styled-components";
import { pxToVh } from "../../../utils/pxToVh";
import { theme } from "../../../styles/theme";

export const BloodResultContainer = styled.div`
  margin-top: ${pxToVh(12)};
  width: 100%;
  height: ${pxToVh(242)};
  border: ${({ theme }) => `1px solid ${theme.palette.message[300]}`};
  border-radius: ${pxToVh(16)};
  padding: ${pxToVh(22)} ${pxToVh(22)};
`;

export const RowSvg = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxToVh(6)};

  svg {
    width: ${pxToVh(20)};
    height: ${pxToVh(20)};
  }
`;

export const BloodInfoContainer = styled.div`
  margin-top: ${pxToVh(22)};
  display: flex;
  flex-direction: column;
  gap: ${pxToVh(10)};
`;

export const BloodInfo = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${pxToVh(7.5)} 0;
`;

export const BloodLine = styled.div`
  width: 100%;
  height: ${pxToVh(1)};
  background-color: ${({ theme }) => theme.palette.message[300]};
  margin-top: ${pxToVh(8)};
  margin-bottom: ${pxToVh(10)};
`;

export const SquareTitle = styled.span`
  font-size: ${theme.fontSize[16]};
  font-weight: ${theme.fontWeight.bold};
`;

export const KeyContent = styled.span`
  font-size: ${theme.fontSize.ladium};
  font-weight: ${theme.fontWeight.bold};
  opacity: 0.7;
`;

export const ValueContent = styled.span`
  font-size: ${theme.fontSize[16]};
  font-weight: ${theme.fontWeight.bold};
`;
