import React from "react";
import { Center, Pattern } from "../../styles/GlobalStyle";

const Layout = ({
  children,
  isBlood,
}: {
  children: React.ReactElement;
  isBlood?: boolean;
}) => {
  return (
    <Center $isBlood={isBlood}>
      <Pattern $top="-7%" $right="-7%" />
      <Pattern $top="60%" $right="70%" />
      {children}
    </Center>
  );
};

export default Layout;
