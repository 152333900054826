import { theme } from "../styles/theme";
import * as Svg from "../components/icon/index";
import useLocalization from "../hooks/useLocalization";

export type TQueryToObjective = {
  [index: string]: {
    title: string;
    unit?: string;
    subUnit?: string | string[];
    subUnitSize?: string;
    subUnitColor?: string;
    standard?: string;
  };
};

export const ToLang = () => {
  const lang = useLocalization();
  const queryToObjective: TQueryToObjective = {
    hr: {
      title: lang.query_info_hr_title,
      unit: lang.query_info_hr_unit,
      subUnit: lang.query_info_hr_subuUnit,
      subUnitSize: theme.fontSize.medium,
      subUnitColor: theme.palette.black,
      standard: lang.query_info_hr_standard,
    },
    resp: {
      title: lang.query_info_rep_title,
      unit: lang.query_info_rep_unit,
      subUnit: lang.query_info_rep_subuUnit,
      subUnitSize: theme.fontSize.medium,
      subUnitColor: theme.palette.black,
      standard: lang.query_info_rep_standard,
    },
    fatigue: {
      title: lang.query_info_fatigue_title,
      standard: lang.query_info_fatigue_standard,
    },
    stress: {
      title: lang.query_info_stress_title,
      standard: lang.query_info_stress_standard,
    },
    bp_sys: {
      title: lang.query_info_bp_title,
      subUnitSize: theme.fontSize.small,
      subUnitColor: theme.palette.message[300],
      // subUnit: ["(SYS)", "(DIA)"],
      standard: "",
    },
    temp: {
      title: lang.query_info_temp_title,
      standard: lang.query_info_temp_standard,
    },
    alcohol: {
      title: lang.query_info_alcohol_title,
    },
  };

  const labelByStatus: {
    [index: string]: { title: string; backgroundColor: string; color: string };
  } = {
    HEALTHY: {
      title: lang.status_healthy,
      backgroundColor: theme.palette.white,
      color: theme.palette.black,
    },
    NORMAL: {
      title: lang.status_normal,
      backgroundColor: theme.palette.danger[200],
      color: theme.palette.black,
    },
    ATTENTION: {
      title: lang.status_attention,
      backgroundColor: theme.palette.danger[300],
      color: theme.palette.white,
    },
    WARNING: {
      title: lang.status_warning,
      backgroundColor: theme.palette.danger[400],
      color: theme.palette.white,
    },
    DANGER: {
      title: lang.status_danger,
      backgroundColor: theme.palette.danger.primary,
      color: theme.palette.white,
    },
  };

  const tempGrade: {
    [index: string]: { title: string; backgroundColor: string; color: string };
  } = {
    HEALTHY: {
      title: lang.temp_healthy,
      backgroundColor: theme.palette.white,
      color: theme.palette.black,
    },
    FEVER: {
      title: lang.temp_fever,
      backgroundColor: theme.palette.danger.primary,
      color: theme.palette.white,
    },
    HYPOTHERMIA: {
      title: lang.temp_hypothermia,
      backgroundColor: theme.palette.message[300],
      color: theme.palette.white,
    },
  };

  const svgByStatus: { [index: string]: React.ReactNode } = {
    HIGH: <Svg.Up />,
    LOW: <Svg.Down />,
  };

  const calcByScore = (score: number) => {
    if (score >= 90) return 1;
    else if (80 <= score && score <= 89) return 2;
    else if (70 <= score && score <= 79) return 3;
    else if (60 <= score && score <= 69) return 4;
    else return 5;
  };

  const titleByScore: { [index: string]: string[] } = {
    1: [lang.title_1_index_0, lang.title_1_index_1],
    2: [lang.title_2_index_0, lang.title_2_index_1],
    3: [lang.title_3_index_0, lang.title_3_index_1],
    4: [lang.title_4_index_0, lang.title_4_index_1],
    5: [lang.title_5_index_0, lang.title_5_index_1],
    6: [lang.title_5_index_0, lang.title_6_index_1],
  };

  return {
    queryToObjective,
    labelByStatus,
    tempGrade,
    svgByStatus,
    calcByScore,
    titleByScore,
  };
};
