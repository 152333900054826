import styled from "styled-components";
import { pxToVh } from "../../utils/pxToVh";

export const LebelConatiner = styled.div<{ $svgFill?: string }>`
  display: flex;
  flex-direction: row;
  gap: ${pxToVh(4)};

  svg {
    width: ${pxToVh(22)};
    height: ${pxToVh(22)};
    fill: ${({ $svgFill }) => $svgFill};
    box-shadow: 0px 1px 5px 0px rgba(50, 50, 71, 0.07);
    border-radius: 50%;
  }
`;

export const LabelCircle = styled.div<{
  $color?: string;
  $backgroundColor?: string;
  $alcoholState?: string;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  height: ${pxToVh(22)};
  padding: 0 ${pxToVh(12)};
  border-radius: ${pxToVh(20)};
  color: ${({ $color }) => $color};
  font-size: ${({ theme }) => theme.fontSize.small};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  box-shadow: ${({ $alcoholState }) =>
    $alcoholState ? "" : "0px 1px 5px 0px rgba(50, 50, 71, 0.07)"};
  border: ${({ $alcoholState }) => `1px solid ${$alcoholState}`};
`;
