import styled from "styled-components";

export const BodyContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ValueTitle = styled.span`
  font-size: ${({ theme }) => theme.fontSize.large};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
`;

export const ValueSub = styled.span<{
  $subUnitSize?: string;
  $subUnitColor?: string;
}>`
  font-size: ${({ theme, $subUnitSize }) =>
    $subUnitSize || theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme, $subUnitColor }) => $subUnitColor || theme.palette.black};
`;

export const StandardValue = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.medium};
  font-weight: ${({ theme }) => theme.fontWeight.semiBold};
  color: ${({ theme }) => theme.palette.message[400]};
`;
