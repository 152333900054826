import React from "react";
import {
  BannerContainer,
  LinkButton,
  Content,
  ContentContainer,
  Title,
  Bold,
  SubText,
  Free,
  Absolute,
} from "./LiftStyles";
import * as Svg from "../icon/index";

const Life = () => {
  const href = () => {
    window.open("http://pf.kakao.com/_wxctws/chat");
  };
  return (
    <BannerContainer>
      <ContentContainer>
        <Title>간호사 건강 상담 서비스</Title>
        <Content>
          본인 또는 가족에 한하여 1회 무료로 서비스를 이용하실 수 있습니다.
        </Content>
      </ContentContainer>
      <LinkButton onClick={href}>
        <Absolute>
          <div>
            <Bold>카카오톡</Bold>
            <SubText>
              으로 서비스 문의
              <Free>(무료)</Free>
            </SubText>
          </div>
          <Svg.ArrowRight className="arrow" />
        </Absolute>
        <Svg.Kaka className="kaka" />
        <Svg.KakaBackground className="background" />
      </LinkButton>
    </BannerContainer>
  );
};

export default Life;
