import styled from "styled-components";
import { pxToVh } from "../../utils/pxToVh";

export const CardConatiner = styled.div<{
  $isAlcohol: boolean;
  $isTrueAlcohol: boolean;
  $alcoholState?: string;
}>`
  width: 100%;
  height: ${({ $isAlcohol }) => ($isAlcohol ? pxToVh(36) : pxToVh(69))};
  border: ${({ $alcoholState }) => `1px solid ${$alcoholState}`};
  border-radius: ${({ $isAlcohol }) => ($isAlcohol ? pxToVh(30) : pxToVh(17))};
  box-shadow: 0px 4px 4px 0px rgba(254, 163, 180, 0.07);
  background: ${({ $isTrueAlcohol }) =>
    $isTrueAlcohol
      ? "linear-gradient(122deg, rgba(255, 68, 68, 0.20) 30.72%, rgba(255, 68, 68, 0.00) 113.26%)"
      : "linear-gradient(181deg,#fff 1.15%,rgba(255, 255, 255, 0) 98.91%)"};
  backdrop-filter: blur(35px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: ${pxToVh(11)} ${pxToVh(16)};
`;

export const CardListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToVh(6)} 0;
  width: 100%;
  margin-top: ${pxToVh(22)};
`;
