import styled from "styled-components";
import { pxToVh } from "../../../utils/pxToVh";

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${pxToVh(22)};
`;

export const TypeTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.ladium};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.palette.message[400]};
  /* line-height: 150%; */
`;

export const AlcoholValueContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${pxToVh(4)};
`;
