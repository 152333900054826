import React from "react";
import {
  AlcoholValueContainer,
  HeadContainer,
  TypeTitle,
} from "./CardHeadStyles";
import LabelStatus from "../../lebelStatus/LabelStatus";
import { theme } from "../../../styles/theme";

const CardHead = ({
  typeTitle,
  labelInfo,
  svg,
  isAlcohol,
  alcoholState,
}: {
  typeTitle: string;
  labelInfo: {
    title: string;
    backgroundColor: string;
    color: string;
    value?: string;
  };
  svg?: { svg: React.ReactNode; fill: string };
  isAlcohol: boolean;
  alcoholState?: string;
}) => {
  const alcoholValue = {
    ...labelInfo,
    title: `${labelInfo.value}%` || "",
    backgroundColor: "transparent",
    color: alcoholState || theme.palette.message[300],
  };

  return (
    <HeadContainer>
      <TypeTitle>{typeTitle}</TypeTitle>
      <AlcoholValueContainer>
        {isAlcohol && (
          <LabelStatus labelInfo={alcoholValue} alcoholState={alcoholState} />
        )}
        <LabelStatus labelInfo={labelInfo} svg={svg} />
      </AlcoholValueContainer>
    </HeadContainer>
  );
};

export default CardHead;
