import React, { useState } from "react";
import Title from "../components/title/Title";
import Layout from "../components/layout/Layout";
import { pxToVh } from "../utils/pxToVh";
import {
  BloodSquareContainer,
  QuestionLayout,
  ResultBloodComment,
  ResultDetail,
} from "../components/blood/bloodSquare/BloodSquareContainer";
import BloodSquare from "../components/blood/bloodSquare/BloodSquare";
import {
  BloodResult,
  bloodSvg,
  calcBloodStateByPulse,
} from "../utils/bloodSvg";
import Question from "../components/blood/question/Question";
import { theme } from "../styles/theme";
import { ModalType } from "../types/modal.type";
import PopUp from "../components/blood/popUp/PopUp";
import BloodDashBoard from "../components/blood/bloodDashBoard/BloodDashBoard";
import Better from "../components/better/Better";
import { useBlood } from "../hooks/useBlood";
import useLocalization from "../hooks/useLocalization";

const BloodPage = () => {
  const lang = useLocalization();
  const {
    date,
    age,
    gender,
    type,
    bloodvesselAge,
    bloodvesselIndex,
    pulse,
    pulseAvg,
    grade,
  } = useBlood();

  const [isShow, setIsShow] = useState<ModalType>(null);
  const tuneGender = Number(gender) === 0 ? lang.gener_mail : lang.gener_femail;
  const [avgMin, avgMax] = String(pulseAvg).split("~");

  const squareData = [
    [lang.BloodVesselHealthCardUserAge, age],
    [lang.BloodVesselHealthCardBloodVesselAge, bloodvesselAge],
    [
      lang.BloodVesselHealthCardCompareUserAge,
      Number(bloodvesselAge) - Number(age),
    ],
  ];

  const balanceBloodStatus: Record<"row" | "middle" | "high", string> = {
    row: lang.blood_status_row,
    middle: lang.blood_status_middle,
    high: lang.blood_status_high,
  };

  const squareData1 = [
    [
      lang.BloodVesselPulseAverageCardPpgStateTitle,
      balanceBloodStatus[calcBloodStateByPulse(avgMin, avgMax, pulse)],
    ],
    [lang.BloodVesslPulseValue, pulse],
    [lang.BloodVesselPulseAverageCardAgePpgAvgTitle, pulseAvg],
  ];

  const minPulse = 40;
  const maxPulse = 120;
  const rebalance = pulse > 120 ? 100 : ((pulse - minPulse) * 3.75) / 3;
  const avgBlood = (+avgMax - +avgMin) * 2;
  const progressStandard = ((+avgMax - +avgMin) * 3.75) / 3;
  const rebalanceStandard = avgBlood > 120 ? maxPulse : (avgBlood * 3.75) / 3;

  const addZero = (target: number) =>
    String(target).length === 1 ? `0${target}` : target;
  const DMM = addZero(new Date().getMonth() + 1);
  const DDD = addZero(new Date().getDate());
  const DHH = addZero(new Date().getHours());
  const Dmm = addZero(new Date().getMinutes());
  const Dtoday = `${DMM}${DDD}${DHH}${Dmm}`;

  const filterDate = date || Dtoday;
  const [MM, dd, hh, mm] = [
    filterDate.slice(0, 2),
    filterDate.slice(2, 4),
    filterDate.slice(4, 6),
    filterDate.slice(6, 8),
  ];

  const dateGender = `${MM}${lang.month} ${dd}${lang.day} ${hh}:${mm} ${lang.measureStandard} ${age}${lang.age} ${tuneGender}`;

  return (
    <>
      <Layout isBlood>
        <>
          <Title
            title={[BloodResult()[type.toLocaleLowerCase()].title, dateGender]}
            subWeight="700"
          />
          <div style={{ height: pxToVh(40) }} />
          <Title
            title={[
              lang.BloodVesselResultSummaryTitle,
              lang.BloodVesselResultSummaryHealthTypeTitle,
            ]}
            titleSize={theme.fontSize[18]}
          />
          <BloodSquareContainer>
            <BloodSquare
              svg={bloodSvg[grade.toLocaleLowerCase()].type}
              infoPlaceholder={lang.BloodVesselResultSummaryHealthType}
              content={`${
                BloodResult()[type.toLocaleLowerCase()].type
              }(${type}${lang.BloodType})`}
            />
            <BloodSquare
              svg={bloodSvg[grade.toLocaleLowerCase()].chart}
              infoPlaceholder={lang.BloodVesselResultSummaryHealthIndex}
              content={bloodvesselIndex}
            />
          </BloodSquareContainer>
          <ResultBloodComment>
            {BloodResult()[type.toLocaleLowerCase()].comment}
          </ResultBloodComment>
          <QuestionLayout>
            <Question
              content={lang.BloodVesselResultSummaryHealthTypeDialogButton}
              helpClick={() => setIsShow("table")}
            />
            <Question
              content={
                lang.BloodVesselResultSummaryHealthIndexChartDialogButton
              }
              helpClick={() => setIsShow("graph")}
            />
          </QuestionLayout>
          <ResultDetail>{lang.BloodVesselResultDetailTitle}</ResultDetail>
          <BloodDashBoard
            mainTitle={lang.BloodVesselHealthCardTitle}
            contentArr={squareData}
            standardTarget={age}
            target={bloodvesselAge}
            helpClick={() => setIsShow("vascular")}
            progressTitle={[
              lang.BloodVesselHealthCardBloodVesselAge,
              lang.BloodVesselHealthCardUserAge,
            ]}
            isQuestionMark
          />
          <BloodDashBoard
            mainTitle={lang.BloodVesselPulseAverageCardPpgAvg}
            contentArr={squareData1}
            standardTarget={progressStandard}
            min={rebalanceStandard}
            target={rebalance}
            progressTitle={[
              lang.BloodVesselPulseAverageCardUserPpgResultTitle,
              lang.BloodVesselPulseAverageCardAgeRangePpgAvgTitle,
            ]}
          />
          <Better />
        </>
      </Layout>
      {isShow && (
        <PopUp
          isShow={isShow}
          closeModal={(e: React.TouchEvent | React.MouseEvent) =>
            setIsShow(null)
          }
        />
      )}
    </>
  );
};

export default BloodPage;
