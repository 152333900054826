import React from "react";
import * as Svg from "../../icon/index";
import { QuestionComment, SvgContainer } from "./QuestionStyles";

const Question = ({
  content,
  helpClick,
}: {
  content: string;
  helpClick: () => void;
}) => {
  return (
    <SvgContainer onClick={helpClick}>
      <Svg.Help />
      <QuestionComment>{content}</QuestionComment>
    </SvgContainer>
  );
};

export default Question;
