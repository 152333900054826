import React from "react";
import { TitleContainer, TitleText } from "./TitleStyles";
import SubTitle from "../subTitle/SubTitle";

const Title = ({
  title,
  titleSize,
  subWeight,
}: {
  title: string[];
  titleSize?: string;
  subWeight?: string;
}) => {
  return (
    <TitleContainer>
      <TitleText $titleSize={titleSize}>{title[0]}</TitleText>
      <SubTitle subTitle={title[1]} subWeight={subWeight} />
    </TitleContainer>
  );
};

export default Title;
