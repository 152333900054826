import {
  BloodInfo,
  BloodInfoContainer,
  BloodLine,
  BloodResultContainer,
  KeyContent,
  RowSvg,
  SquareTitle,
  ValueContent,
} from "./BloodDashBoardStyles";
import * as Svg from "../../icon/index";
import BloodProgress from "../bloodProgress/BloodProgress";

interface BloodDashBoardProps {
  mainTitle: string;
  contentArr: (string | number)[][];
  target: number;
  standardTarget: number;
  min?: number;
  isQuestionMark?: boolean;
  progressTitle: string[];
  helpClick?: () => void;
}

const BloodDashBoard = ({
  mainTitle,
  contentArr,
  target,
  standardTarget,
  min,
  isQuestionMark,
  progressTitle,
  helpClick,
}: BloodDashBoardProps) => {
  return (
    <BloodResultContainer>
      <RowSvg>
        <SquareTitle>{mainTitle}</SquareTitle>
        {isQuestionMark && <Svg.Help onClick={helpClick} />}
      </RowSvg>
      <BloodInfoContainer>
        {contentArr.map((keyItem, i) => (
          <BloodInfo key={i}>
            <KeyContent>{keyItem[0]}</KeyContent>
            <ValueContent>{keyItem[1]}</ValueContent>
          </BloodInfo>
        ))}
        <BloodLine />
        <div>
          <BloodProgress title={progressTitle[0]} target={target} />
          <BloodProgress
            title={progressTitle[1]}
            target={standardTarget}
            min={min}
            isStandard
          />
        </div>
      </BloodInfoContainer>
    </BloodResultContainer>
  );
};

export default BloodDashBoard;
