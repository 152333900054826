import React from "react";
import { ResultDetail } from "../blood/bloodSquare/BloodSquareContainer";
import {
  BetterComment,
  BetterCommentTitle,
  BetterLayout,
  Categoery,
  CategoryContainer,
  CommentContainer,
  ScollX,
} from "./BetterStyles";
import { BloodBetterComment } from "../../utils/betterStore";
import useLocalization from "../../hooks/useLocalization";

const Better = () => {
  const [currentCategory, setCurrentCategory] = React.useState(0);
  const lang = useLocalization();
  return (
    <BetterLayout>
      <ResultDetail>{lang.BetterTitle}</ResultDetail>
      <ScollX>
        <CategoryContainer>
          {BloodBetterComment().type.map((item, i) => (
            <Categoery
              key={i}
              $isSelect={item === BloodBetterComment().type[currentCategory]}
              onClick={() => setCurrentCategory(i)}
            >
              {item}
            </Categoery>
          ))}
        </CategoryContainer>
      </ScollX>
      <CommentContainer>
        <BetterCommentTitle>
          {BloodBetterComment().title[currentCategory]}
        </BetterCommentTitle>
        <BetterComment>
          {BloodBetterComment().content[currentCategory]}
        </BetterComment>
      </CommentContainer>
    </BetterLayout>
  );
};

export default Better;
