import styled from "styled-components";
import { pxToVh } from "../../../utils/pxToVh";
import { theme } from "../../../styles/theme";

export const DataContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${pxToVh(8)};
`;

export const DataInProgress = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  align-items: center;
`;

export const ProgressTitle = styled.span`
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.regular};
  color: ${theme.palette.message[300]};
  white-space: pre;
  width: 30%;
`;

export const WidthBarLayout = styled.div<{
  $width?: string;
  $min?: number;
  $max?: number;
}>`
  position: relative;
  width: ${({ $width }) => ($width ? $width : "100%")};
  height: ${pxToVh(8)};
  background-color: ${({ theme }) => theme.palette.message[500]};
  border-radius: ${pxToVh(4)};
  &::before {
    content: "";
    position: absolute;
    opacity: 0;
    /* animation:  0.6s ease-in-out 1s forwards; */
    top: ${pxToVh(-10)};
    left: ${({ $min }) => `${$min}%`};
    width: ${({ $min }) => ($min ? pxToVh(1) : 0)};
    height: ${pxToVh(8)};
    background-color: ${({ theme }) => theme.palette.message[500]};
  }
  &::after {
    content: "";
    position: absolute;
    opacity: 0;
    /* animation:  0.6s ease-in-out 1s forwards; */
    top: ${pxToVh(-10)};
    left: ${({ $max }) => `${$max}%`};
    width: ${({ $max }) => ($max ? pxToVh(1) : 0)};
    height: ${pxToVh(8)};
    background-color: ${({ theme }) => theme.palette.message[500]};
  }
`;

export const ProgressValue = styled.div<{
  $isStandard?: boolean;
  $target: number;
  $min?: number;
}>`
  position: absolute;
  transition: 1s ease-in-out;
  background: ${({ $isStandard, theme }) =>
    $isStandard
      ? theme.palette.message[400]
      : `linear-gradient(
    270deg,
    #FDA29B 70.93%,
    rgba(255, 255, 255, 0.80) 111.06%
  )`};
  border-radius: ${pxToVh(4)};
  top: 0;
  left: ${({ $min }) => `${$min}%` || 0};
  width: ${({ $target }) => `${$target}%`};
  height: 100%;
`;
