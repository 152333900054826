import styled from "styled-components";
import { pxToVh } from "../../utils/pxToVh";
import { theme } from "../../styles/theme";

export const BetterLayout = styled.div`
  width: 100%;
  margin-top: ${pxToVh(64)};
`;

export const ScollX = styled.div`
  overflow-x: auto;
  white-space: nowrap;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const CategoryContainer = styled.div`
  display: flex;
  align-items: center;
  height: ${pxToVh(40)};
  margin-top: ${pxToVh(14)};
`;

export const Categoery = styled.span<{ $isSelect: boolean }>`
  height: 100%;
  padding: 0 ${pxToVh(22)};
  border-radius: ${pxToVh(19)};
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  font-size: ${theme.fontSize[16]};
  font-weight: ${({ $isSelect, theme }) =>
    $isSelect ? theme.fontWeight.bold : theme.fontWeight.regular};
  transition: 0.3s background-color;
  color: ${({ $isSelect, theme }) =>
    $isSelect ? theme.palette.white : theme.palette.message[300]};
  background-color: ${({ $isSelect, theme }) =>
    $isSelect ? theme.palette.message[400] : "transparent"};
`;

export const CommentContainer = styled.div`
  min-height: ${pxToVh(140)};
  margin-top: ${pxToVh(15)};
  margin-bottom: ${pxToVh(20)};
`;

export const BetterCommentTitle = styled.p`
  font-size: ${theme.fontSize[20]};
  font-weight: ${theme.fontWeight.bold};
  line-height: 150%;
`;

export const BetterComment = styled.span`
  font-size: ${theme.fontSize[16]};
  line-height: 150%;
`;
