import styled from "styled-components";

export const Absolute = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: brightness(50%);
  -webkit-backdrop-filter: brightness(50%);
`;
