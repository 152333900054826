import styled from "styled-components";
import { pxToVh } from "../../../utils/pxToVh";
import { theme } from "../../../styles/theme";

export const SvgContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${pxToVh(3)};
  margin-bottom: ${pxToVh(4)};
  svg {
    width: ${pxToVh(18)};
    height: ${pxToVh(18)};
  }
`;

export const QuestionComment = styled.span`
  line-height: 150%;
  text-decoration-line: underline;
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.bold};
  color: ${theme.palette.message[400]};
  opacity: 0.7;
`;
