import styled from "styled-components";
import { pxToVh } from "../../utils/pxToVh";

export const TitleContainer = styled.section`
  /* padding-top: ${pxToVh(73)}; */
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const TitleText = styled.p<{ $titleSize?: string }>`
  font-size: ${({ theme, $titleSize }) => $titleSize || theme.fontSize.large};
  font-weight: 700;
  line-height: 150%;
  margin: 0;
`;
