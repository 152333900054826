import React from "react";
import { CardConatiner } from "./CardStyles";
import CardBody from "./body/CardBody";
import CardHead from "./head/CardHead";
import { ToLang } from "../../utils/queryToObjective";
import { TFilteredData } from "../../types/queryData.type";
import { theme } from "../../styles/theme";
import useLocalization from "../../hooks/useLocalization";

const Card = ({ cardData }: { cardData: TFilteredData }) => {
  const lang = useLocalization();
  const findObject = ToLang().queryToObjective[cardData.key];
  let [healthStatus, svgStatus] = ["", ""];
  const isAlcohol = cardData.key === "alcohol";
  const isTrueAlcohol = cardData.value === "true";
  if (cardData.status) {
    [healthStatus, svgStatus] = cardData.status.split("_");
  }

  let labelData;
  if (isAlcohol) {
    labelData = {
      title: isTrueAlcohol
        ? lang.alcohol_status_true
        : lang.alcohol_status_false,
      backgroundColor: isTrueAlcohol
        ? theme.palette.danger.primary
        : theme.palette.white,
      color: isTrueAlcohol ? theme.palette.white : theme.palette.black,
      value: cardData.sideValue,
    };
  } else if (cardData.key === "temp" && cardData.status) {
    labelData = ToLang().tempGrade[cardData.status];
  } else {
    labelData = ToLang().labelByStatus[healthStatus];
  }

  const filterBpValue =
    cardData.key === "bp_sys"
      ? cardData.value.replace("_", " / ")
      : cardData.value;
  // cardData.key === "bp_sys" ? cardData.value.split("_") : cardData.value;

  const alcoholState =
    isAlcohol && isTrueAlcohol ? "positive" : isAlcohol ? "negative" : "none";
  const divideAlcoholState: Record<"positive" | "negative" | "none", string> = {
    positive: theme.palette.danger.primary,
    negative: theme.palette.message[300],
    none: theme.palette.white,
  };

  return (
    <CardConatiner
      $isAlcohol={isAlcohol}
      $isTrueAlcohol={isTrueAlcohol}
      $alcoholState={divideAlcoholState[alcoholState]}
    >
      <CardHead
        typeTitle={findObject.title}
        labelInfo={labelData}
        isAlcohol={isAlcohol}
        alcoholState={divideAlcoholState[alcoholState]}
        svg={{
          svg: ToLang().svgByStatus[svgStatus],
          fill: ToLang().labelByStatus[healthStatus]?.backgroundColor,
        }}
      />
      {cardData.key !== "alcohol" && (
        <CardBody
          value={filterBpValue}
          subUnit={findObject.subUnit}
          subUnitSize={findObject.subUnitSize}
          subUnitColor={findObject.subUnitColor}
          unit={findObject.unit}
          standard={findObject.standard}
        />
      )}
    </CardConatiner>
  );
};

export default Card;
