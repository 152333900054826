export type BloodQueryType = {
  date: string;
  age: number;
  gender: number;
  type: string;
  bloodvesselAge: number;
  bloodvesselIndex: number;
  pulse: number;
  pulseAvg: number;
  grade: string;
};

export const defaulBloodQuery: BloodQueryType = {
  date: "",
  age: 0,
  gender: 0,
  type: "",
  bloodvesselAge: 0,
  bloodvesselIndex: 0,
  pulse: 0,
  pulseAvg: 0,
  grade: "",
};

export const bloodQueryItems = [
  "date",
  "age",
  "gender",
  "type",
  "bloodvesselAge",
  "bloodvesselIndex",
  "pulse",
  "pulseAvg",
  "grade",
];
