import styled, { createGlobalStyle } from "styled-components";
import Bold from "../assets/font/Pretendard-Bold.woff2";
import SemiBold from "../assets/font/Pretendard-SemiBold.woff2";
import Regular from "../assets/font/Pretendard-Regular.woff2";
import Extra from "../assets/font/Pretendard-ExtraBold.woff2";
import { pxToVh } from "../utils/pxToVh";
import { theme } from "./theme";

export const GlobalStyle = createGlobalStyle`
  @font-face {
        font-family: "Pretendard-Bold";
        src: local("Pretendard-Bold"), url(${Bold}) format('woff2'); 
        font-weight: normal;
    }
    @font-face {
        font-family: "Pretendard-Regular";
        src: local("Pretendard-Regular"), url(${Regular}) format('woff2'); 
        font-weight: normal;
    }
    @font-face {
        font-family: "Pretendard-SemiBold";
        src: local("Pretendard-SemiBold"), url(${SemiBold}) format('woff2'); 
        font-weight: normal;
    }
    @font-face {
        font-family: "Pretendard-ExtraBold";
        src: local("Pretendard-ExtraBold"), url(${Extra}) format('woff2'); 
        font-weight: normal;
    }
    * {
        font-family: "Pretendard", sans-serif;
        box-sizing: border-box;
    }
    
    body {
        margin: 0;
        padding: 0;
        width: 100vw;
        min-height: 667px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.90) 15.34%, rgba(227, 227, 227, 0.70) 51.1%, rgba(213, 213, 213, 0.60) 67.92%);
        overflow: auto;
        display: flex;
        justify-content: center;
      }

      button {
        border: none;
      }
      `;

export const Center = styled.div<{ $isBlood?: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${({ $isBlood }) =>
    `${pxToVh(73)} ${pxToVh($isBlood ? 24 : 32)} ${pxToVh(35)} ${pxToVh(
      $isBlood ? 24 : 32
    )}`};
  /* background-color: ${theme.palette.white}; */
  @media screen and (max-width: 500px) {
    overflow-x: hidden;
  }
  max-width: 600px;
`;

export const Pattern = styled.div<{ $top: string; $right: string }>`
  position: absolute;
  top: ${({ $top }) => $top};
  right: ${({ $right }) => $right};
  width: ${pxToVh(145)};
  height: ${pxToVh(145)};
  border-radius: 50%;
  opacity: 0.2;
  filter: blur(42px);
  background-color: ${({ theme }) => theme.palette.danger[200]};
`;

export const FullHeight = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
