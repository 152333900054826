import React from "react";
import { LabelCircle, LebelConatiner } from "./LabelStatusStyles";
import { theme } from "../../styles/theme";

const LabelStatus = ({
  labelInfo,
  svg,
  alcoholState,
}: {
  labelInfo: {
    title: string;
    color: string;
    backgroundColor: string;
  };
  svg?: { svg: React.ReactNode; fill: string };
  alcoholState?: string;
}) => {
  return (
    <LebelConatiner $svgFill={svg?.fill}>
      <LabelCircle
        $color={labelInfo.color}
        $backgroundColor={labelInfo.backgroundColor || theme.palette.white}
        $alcoholState={alcoholState}
      >
        {labelInfo.title}
      </LabelCircle>
      {svg?.svg}
    </LebelConatiner>
  );
};

export default LabelStatus;
