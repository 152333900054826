import LocalizedStrings from "react-localization";
import { useParams } from "react-router-dom";
import { LangType } from "../types/lang.type";
import { localIndex } from "../localization";

const useLocalization = () => {
  const { lang } = useParams();
  let translation = new LocalizedStrings(localIndex);

  // translation.setLanguage((lang === "ja" ? "ja" : "ko") as LangType);
  translation.setLanguage((lang || "ko") as LangType);

  return translation;
};

export default useLocalization;
