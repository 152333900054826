import * as Svg from "../components/icon/index";
import useLocalization from "../hooks/useLocalization";
import { LangType } from "../types/lang.type";
import { pxToVh } from "./pxToVh";

export const TitleStore = (): Record<
  "graph" | "table" | "vascular",
  { title: string; sub: string }
> => {
  const lang = useLocalization();
  return {
    graph: {
      title: lang.BloodVesselHealthIndexChartTypeDialogTitle,
      sub: lang.BloodVesselHealthIndexChartTypeDialogSubTitle,
    },
    table: {
      title: lang.BloodVesselHealthTypeDialogTitle,
      sub: lang.BloodVesselHealthTypeDialogSubTitle,
    },
    vascular: {
      title: lang.BloodVesselHealthAgeDialogTitle,
      sub: lang.BloodVesselHealthAgeDialogSubTitle,
    },
  };
};

export const popUpSvg: Record<
  "graph" | "table" | "vascular",
  {
    chart: Record<LangType, React.ReactElement>;
    info: Record<LangType, React.ReactElement>;
    width: string;
    height?: string;
  }
> = {
  graph: {
    chart: {
      ko: <Svg.InfoChartKO />,
      ja: <Svg.InfoChartKO />,
      en: <Svg.InfoChartKO />,
    },
    info: {
      ko: <Svg.ChartTextKO />,
      ja: <Svg.ChartTextJA />,
      en: <Svg.ChartTextEN />,
    },
    width: pxToVh(200),
  },
  table: {
    chart: { ko: <Svg.TableKO />, ja: <Svg.TableJA />, en: <Svg.TableEN /> },
    info: {
      ko: <Svg.TableInfoKO />,
      ja: <Svg.TableInfoJA />,
      en: <Svg.TableInfoEN />,
    },
    width: pxToVh(260),
  },
  vascular: {
    chart: {
      ko: <Svg.BloodAgeKO />,
      ja: <Svg.BloodAgeJA />,
      en: <Svg.BloodAgeEN />,
    },
    info: { ko: <></>, ja: <></>, en: <></> },
    width: pxToVh(250),
    height: "100%",
  },
};
