import useLocalization from "../hooks/useLocalization";

export const BloodBetterComment = () => {
  const lang = useLocalization();
  return {
    type: [lang.food, lang.exercise],
    title: [lang.better_title_food, lang.better_title_exercise],
    content: [lang.better_content_food, lang.better_content_exercise],
  };
};
