import styled from "styled-components";
import { pxToVh } from "../../utils/pxToVh";

export const BannerContainer = styled.section`
  position: relative;
  width: 100%;
  min-width: 390px;
  height: ${pxToVh(90)};
  background: linear-gradient(180deg, #dcecfe -8.85%, #fff 107.24%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 0 ${pxToVh(28)};

  /* svg {
    position: absolute;
    top: 50%;
    right: 15.5%;
    transform: translate3d(50%, -50%, 0);
    z-index: 1;
  } */
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* padding-right: ${pxToVh(20)}; */
`;

export const Title = styled.div`
  font-size: clamp(${pxToVh(10)}, ${pxToVh(12)}, ${pxToVh(14)});
  font-weight: 900;
  line-height: 150%;
  color: #21285d;
`;

export const Content = styled.div`
  font-size: clamp(${pxToVh(6)}, ${pxToVh(7)}, ${pxToVh(8)});
  font-weight: 500;
  line-height: 130%;
  color: #7c7c7c;
  width: 80%;
`;

export const LinkButton = styled.button`
  position: relative;
  border: none;
  border-radius: ${pxToVh(20)};
  background: linear-gradient(
    239deg,
    #fff 36.46%,
    rgba(255, 255, 255, 0.5) 55.76%
  );
  width: 100%;
  height: ${pxToVh(30)};
  padding: 0;
  z-index: 2;
  box-shadow: 0px 4px 4px 0px rgba(192, 219, 255, 0.25);
  margin-top: ${pxToVh(10)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .kaka {
    position: absolute;
    top: -70%;
    left: 28%;
  }

  .background {
    position: absolute;
    top: -116%;
    left: 54%;
  }
`;

export const Bold = styled.span`
  font-size: clamp(${pxToVh(6)}, ${pxToVh(10)}, ${pxToVh(12)});
  font-weight: 900;
  color: #21285d;
`;

export const SubText = styled.span`
  font-size: clamp(${pxToVh(4)}, ${pxToVh(6)}, ${pxToVh(8)});
  font-weight: 700;
  vertical-align: middle;
  color: #21285d;
`;

export const Free = styled.span`
  font-size: clamp(${pxToVh(4)}, ${pxToVh(6)}, ${pxToVh(8)});
  font-weight: 400;
  vertical-align: middle;
  color: #21285d;
`;

export const Absolute = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;

  .arrow {
    width: ${pxToVh(10)};
    height: ${pxToVh(10)};
    margin-left: ${pxToVh(6)};
  }
`;
